import 'swiper/dist/css/swiper.min.css';
import Swiper from 'swiper';
import 'jquery.counterup';
import 'jquery-noconflict';
import '../progress'; 
import './layout';

document.addEventListener("DOMContentLoaded",function(e) {
    const main = document.getElementById('main'),
        news_url = main.getAttribute('data-news'),
        event_url = main.getAttribute('data-events');

    fetch(
        news_url
    ).then(
        response => response.ok ? response.text() : Promise.reject({
            status: response.status,
            statusText: response.statusText
        })
    ).then(
        str => document.getElementById('news').innerHTML = str
    ).catch(error => console.log(error))

});

